/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-11-18T10:11:48+01:00
 * @Copyright: Technology Studio
 * @flow
**/

import { graphql } from 'gatsby';
import { withI18next } from 'gatsby-plugin-modern-react-i18next';

import Container from 'components/Container';
import TitleAndMetaTags from 'components/TitleAndMetaTags';
import Layout from 'components/Layout';
import React from 'react';
import {sharedStyles} from 'theme';

type Props = {
  location: Location,
};

const PageNotFound = ({location}: Props) => (
  <Layout location={location}>
    <Container>
      <div css={sharedStyles.articleLayout.container}>
        <div css={sharedStyles.articleLayout.content}>
          <h1>Page Not Found</h1>
          <TitleAndMetaTags title="Page Not Found" />
          <div css={sharedStyles.markdown}>
            <p>We couldn't find what you were looking for.</p>
            <p>
              Please contact the owner of the site that linked you to the
              original URL and let them know their link is broken.
            </p>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
);

export default withI18next()(PageNotFound);

export const pageQuery = graphql`
  query PageNotFoundMarkdown($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
  }
`;