/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-21T13:08:49+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import React from 'react';

import type {Node} from 'react';

import './Container.css';

/**
 * This component wraps page content sections (eg header, footer, main).
 * It provides consistent margin and max width behavior.
 */
const Container = ({children}: {children: Node}) => (
  <div className="txo-container">{children}</div>
);

export default Container;
